import React, { useEffect, useState } from "react";
import { ref, get } from "firebase/database";
import { realtimeDatabase } from "../firebase"; // Import your initialized Firebase instance
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Table.css";

const ContactRequestsTable = () => {
  const [contactRequests, setContactRequests] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Fetch the contact requests when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(realtimeDatabase, "ContactRequests");
      try {
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const formattedData = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setContactRequests(formattedData);
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching data from Firebase:", error);
        toast.error("Error fetching data. Please try again later.");
      }
    };
    fetchData();
  }, []);

  // Handle checkbox selection
  const handleCheckboxChange = (id) => {
    setSelectedContacts((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((contactId) => contactId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  // Handle "Select All" checkbox
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(contactRequests.map((req) => req.id));
    }
    setSelectAll(!selectAll);
  };

  const handleSendEmail = (name, email) => {
    const subject = "We’ve Received Your Request – Let’s Schedule a Call";
    const body = `Dear ${name},

      Thank you for reaching out to us. We have successfully received your request and are eager to assist you.

      To ensure we address your needs effectively, we would like to schedule a call at a time convenient for you. Below are some available slots:

      - Monday, December 18, 2024, at 10:00 AM (GMT+5:30)
      - Wednesday, December 20, 2024, at 3:00 PM (GMT+5:30)
      - Friday, December 22, 2024, at 5:00 PM (GMT+5:30)

      If none of these times work for you, please let us know your preferred timeline, and we’ll do our best to accommodate.

      We can provide a Microsoft Teams or Google Meet link for the call based on your preference. Kindly confirm your choice of platform along with the selected time slot.

      We look forward to connecting with you and assisting you further. If you have any urgent questions in the meantime, please don’t hesitate to get in touch.

      Best regards,
      [Your Name]
      [Your Position]
      [Company Name]
      [Phone Number]
      [Email Address]`;

    window.location.href = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  // Handle "Send Email" to selected contacts
  const handleSendEmailToSelected = () => {
    if (selectedContacts.length === 0) {
      toast.warning("Please select at least one contact to send email.");
      return;
    }

    selectedContacts.forEach((id) => {
      const contact = contactRequests.find((req) => req.id === id);
      if (contact) {
        handleSendEmail(contact.name, contact.email);
      }
    });
  };

  return (
    <div className="contact-requests-container">
      <h1 className="contact-requests-heading">Contact Requests</h1>

      {/* Table displaying the contact requests */}
      <div className="contact-requests-table-container">
        <table className="contact-requests-table table-striped">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  className="form-check-input"
                />
              </th>
              <th>Name</th>
              <th>Email</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {contactRequests.map((contact) => (
              <tr key={contact.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedContacts.includes(contact.id)}
                    onChange={() => handleCheckboxChange(contact.id)}
                    className="form-check-input"
                  />
                </td>
                <td>{contact.name}</td>
                <td>{contact.email}</td>
                <td>
                  <textarea
                    value={contact.message}
                    readOnly
                    rows={3}
                    style={{
                      width: '100%',
                      padding: '5px',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      backgroundColor: '#f9f9f9',
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Button to send email to selected contacts */}
      {selectedContacts.length > 0 && (
        <button
          className="contact-requests-button btn btn-success mt-3"
          onClick={handleSendEmailToSelected}
        >
          Send Email to Selected
        </button>
      )}

      <ToastContainer />
    </div>
  );
};

export default ContactRequestsTable;
