import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.css";
import NotFound from "./Not_Found";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Page from "./pages";
import ServicesGrid from "./pages/SevicesGrid";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Layout from "./pages/Layout";
import AboutMore from "./pages/AboutMore";
import ServiceDetail from "./pages/ServiceDetail";
import { contact_data } from "./data/content";
import { ThemeProvider } from "./ThemeContext";
import PrivateRoute from './PrivateRoute';


const metadata = {
  "/": {
    title: "Home | Pervasive Services",
    description: "Welcome to Pervasive Services, your destination for innovative software solutions.",
  },
  "/home": {
    title: "Home | Pervasive Services",
    description: "Discover our cutting-edge software services.",
  },
  "/services": {
    title: "Services | Pervasive Services",
    description: "Explore our diverse range of software and IT services.",
  },
  "/services/1": {
    title: "Software Development Services | Pervasive Services",
    description: "Comprehensive development services for Custom Software, Web, and Mobile Apps tailored to your unique business needs.",
  },
  "/services/2": {
    title: "Software as a Service (SaaS) Solutions | Pervasive Services",
    description: "Scalable and secure SaaS solutions tailored for industry-specific needs.",
  },
  "/services/3": {
    title: "Cloud Application Development | Pervasive Services",
    description: "Empowering businesses with scalable and flexible cloud solutions.",
  },
  "/services/4": {
    title: "Software Integration | Pervasive Services",
    description: "Unlock efficiency by seamlessly connecting diverse software systems.",
  },
  "/services/5": {
    title: "Software Testing and Quality Assurance | Pervasive Services",
    description: "Ensuring performance and reliability through rigorous testing processes.",
  },
  "/services/6": {
    title: "IT Support Services | Pervasive Services",
    description: "End-to-end IT support for reliable and efficient system operations.",
  },
  "/about": {
    title: "About Us | Pervasive Services",
    description: "Learn about our journey, vision, and commitment to excellence.",
  },
  "/about-more": {
    title: "More About Us | Pervasive Services",
    description: "Dive deeper into our story and what makes us unique.",
  },
  "/contact": {
    title: "Contact Us | Pervasive Services",
    description: "Get in touch with us for your software needs.",
  },
  "/privacy-policy": {
    title: "Privacy Policy | Pervasive Services",
    description: "Understand how we protect your data and privacy.",
  },
  "/terms-and-conditions": {
    title: "Terms and Conditions | Pervasive Services",
    description: "Read the terms and conditions of using our services.",
  },
  "*": {
    title: "404 | Pervasive Services",
    description: "Page not found.",
  },
};


// Scroll to top when the route changes
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

// Metadata Component for dynamic Helmet integration
const Metadata = () => {
  const { pathname } = useLocation();
  const meta = metadata[pathname] || metadata["*"];

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
    </Helmet>
  );
};

function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Metadata />
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Page />
                </Layout>
              }
            />
            <Route
              path="/home"
              element={
                <Layout>
                  <Page />
                </Layout>
              }
            />
            <Route
              path="/services"
              element={
                <Layout>
                  <ServicesGrid />
                </Layout>
              }
            />
            <Route
              path="/about"
              element={
                <Layout>
                  <About />
                </Layout>
              }
            />
            <Route
              path="/about-more"
              element={
                <Layout>
                  <AboutMore />
                </Layout>
              }
            />
            <Route
              path="/services/:service__id"
              element={
                <Layout>
                  <ServiceDetail />
                </Layout>
              }
            />
            <Route
              path="/contact"
              element={
                <Layout>
                  <Contact
                    contact__id={contact_data[0].id}
                    contact__title={contact_data[0].home__title}
                    contact__subtitle={contact_data[0].home__subtitle}
                    contact__button={contact_data[0].home__button}
                    contact__img={contact_data[0].home__img}
                  />
                </Layout>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/admin" element={<PrivateRoute />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
