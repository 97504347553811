import { logo } from "../../assets/img";
import { Link } from "react-router-dom";
import { socialMediaLinks } from "../../data/content";
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import { services__Data } from '../../data/data'; // Make sure to import your data


function Footer() {

  const divStyle1 = {
    // display: "inline-block",
    width: "20%",
    margin: "2px",
  };
  const divStyle2 = {
    // display: "inline-block",
    width: "80%",
    fontSize: "18px",
    margin: "2px",

    fontFamily: "Comic Sans MS", // Set the font family to "Comic Sans MS"
    whiteSpace: "nowrap", // This ensures that text doesn't wrap to the next line
    position: "relative", // Enable relative positioning
    top: "-1.5px", // Move the text 0.05 pixels up
  };
  const containerStyle = {
    display: "flex", // Display children in a row
    alignItems: "center", // Center children vertically
  };
 
  const navigate = useNavigate();
  // const handleClickLink = (event,path) => {
  //   // Add your custom logic for navigation
  //   // For example, you can use react-router-dom to navigate programmatically
  //   // history.push('/contact');
    
  //   // Prevent the default behavior of the anchor link
  //   event.preventDefault();
  //   navigate(path);
  // };


  const handleClick = (event, path) => {
    // event.preventDefault();
    event.preventDefault();
    navigate(path);
    // Add your custom logic for navigation
    // For example, you can use react-router-dom to navigate programmatically
    // history.push(path);
  };

  return (
    // <!--========== FOOTER ==========-->
    <footer className="footer section bd-container">
      <div className="footer__container bd-grid">
        <div className="footer__content">
          {/* <a href="#" className="footer__logo">
            Pervasive Services
          </a> */}
          <Link to="/" className="footer__logo" onClick={(event) => handleClick(event, '/home')}>
            <div style={containerStyle}>
              <div style={divStyle1}>
                <img
                  src={logo}
                  alt={logo}
                  width={50}
                  height={50}
                  className=""
                />
              </div>
              <div style={divStyle2}>Pervasive Services</div>
            </div>
          </Link>

          <span className="footer__description">
            Bring you idea in reality with us
          </span>
          <div>
            {socialMediaLinks.map((link, index) => (
              <a key={index} href={link.url} className="footer__social">
                <i className={link.iconClass}></i>
              </a>
            ))}
          </div>
        </div>
        <div className="footer__content">
      {/* <h3 className="footer__title">Services</h3> */}
      {/* <ul>
        {services__Data.map((service) => (
          <li key={service.id}>
            <Link to={`/services/${service.id}`} className="footer__link" onClick={(event) => handleClick(event, `/services/${service.id}`)}>
              {service.title}
            </Link>
          </li>
        ))}
      </ul> */}
    </div>

        {/* <div className="footer__content">
          <h3 className="footer__title">Services</h3>
          <ul>
            <li>
              <a href="/services" className="footer__link">
                It support
              </a>
            </li>
            <li>
              <a href="/services" className="footer__link">
                Application Development
              </a>
            </li>
            <li>
              <a href="/services" className="footer__link">
                customer Support
              </a>
            </li>
            <li>
              <a href="/services" className="footer__link">
                Bugs/Fixing
              </a>
            </li>
          </ul>
        </div> */}

        <div className="footer__content">
          <h3 className="footer__title">Information</h3>
          <ul>
            {/* <li>
              <a href="#" className="footer__link">
                Event
              </a>
            </li> */}
            <li>
              <Link to="/contact" className="footer__link" onClick={(event) => handleClick(event, '/contact')}>
                Contact us
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="footer__link" onClick={(event) => handleClick(event, '/privacy-policy')}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/terms-and-conditions" className="footer__link" onClick={(event) => handleClick(event, '/terms-and-conditions')} >
                Terms and Conditions
              </Link>
            </li>
          </ul>
        </div>

        <div className="footer__content">
          <h3 className="footer__title">Address</h3>
          <ul>
            <address>Pune,Maharashtra</address>
            <p className="email-address">sandip.kalsait@pervasive-services.com</p>   
            <p className="email-address">info@pervasive-services.com</p>
            <p className="email-address">admin@pervasive-services.com</p>
            <div>+91 9158713568</div>
          </ul>
        </div>
      </div>

      <p className="footer__copy">
        &#169; {new Date().getFullYear()} Pervasive Services. All right reserved
      </p>
    </footer>
  );
}

export default Footer;
