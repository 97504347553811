import { initializeApp } from "firebase/app";
// import { getAuth, signInAnonymously } from "firebase/auth";
import { getDatabase } from "firebase/database";
// import {
//   initializeAppCheck,
//   ReCaptchaV3Provider,
//   getToken,
// } from "firebase/app-check";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const realtimeDatabase = getDatabase(app);
// const auth = getAuth(app);

// Initialize App Check using ReCaptcha
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
//   isTokenAutoRefreshEnabled: true, // Automatically refresh the token when it expires
// });

// Handle the sign-in anonymously
// signInAnonymously(auth)
//   .then(() => {
//     console.log("Signed in anonymously");
//   })
//   .catch((error) => {
//     console.error("Error signing in anonymously:", error);
//   });

// Fetch App Check token before making requests

// Export Firebase services
export { app, realtimeDatabase };
